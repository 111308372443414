<template>
  <b-table
    responsive
    :fields="tableColumns"
    :items="items"
    empty-text="Nie znaleziono szkoleń."
    tbody-tr-class="cursor-pointer"
    hover
    show-empty
    :sort-by.sync="sortBy"
    :sort-desc.sync="isSortDirDesc"
    :busy="busy"
    @row-clicked="trainingCourseDetailsRedirect"
  >
    <template
      v-if="!items.length"
      #table-busy
    >
      <div class="text-center">
        <b-spinner class="align-middle mr-1" /> Pobieranie listy szkoleń
      </div>
    </template>
    <template #cell(date)="{ item }">
      <div style="white-space: nowrap">
        {{ item.date | date }}<br>{{ item.endDate | date }}
      </div>
    </template>

    <template #cell(time)="{ item }">
      <div style="white-space: nowrap">
        {{ item.date | time }}
      </div>
    </template>

    <template #cell(department)="{ item }">
      {{ resolveDepartment(item.department) }}
    </template>
    <template #cell(departments)="{ item }">
      {{ resolveDepartments(item.departments) }}
    </template>
    <template #cell(invoices)="{ item }">
      <b-button-group
        v-for="invoice in item.invoices"
        :key="invoice.id"
        size="sm"
        class="w-100 mb-50"
      >
        <b-button
          class="btn-icon"
          :variant="invoice.isPaid ? 'success' : 'danger'"
          :title="invoice.isPaid ? 'Opłacona' : 'Nieopłacona'"
        >
          <feather-icon icon="FileIcon" />
        </b-button>
        <b-button
          class="btn-icon"
          variant="light"
          title="Pobierz"
          @click="downloadInvoice(invoice)"
        >
          <feather-icon icon="DownloadIcon" />
        </b-button>
      </b-button-group>
    </template>

    <template
      #cell(priceInGrosze)="{item}"
    >
      {{ item.priceInGrosze | priceInGrosze }}
    </template>

    <template #cell(actions)="data">
      <div style="white-space: nowrap">
        <b-button
          v-if="isManager && trainingCourseIsUpcoming(data.item)"
          size="sm"
          variant="primary"
          :disabled="busy"
          @click="$emit('quickEnrolment', data.item)"
        >
          <feather-icon icon="UserPlusIcon" />
          <span class="align-middle ml-50">Zapisz na szkolenie</span>
        </b-button>
      </div>
    </template>
  </b-table>
</template>

<script>
import {
  BTable, BSpinner, BButton, BButtonGroup,
} from 'bootstrap-vue'
import resolveDepartment from '@/helpers/resolveDepartment'
import resolveDepartments from '@/helpers/resolveDepartments'
import trainingCourseIsUpcoming from '@/helpers/trainingCourseIsUpcoming'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BTable, BSpinner, BButton, BButtonGroup,
  },
  props: {
    items: {
      required: true, type: Array,
    },
    busy: { type: Boolean, default: false },
    managerPastFields: { type: Boolean, default: false },
    managerAvailableTrainingsFields: { type: Boolean, default: false },
  },
  setup() {
    const sortBy = ref('date')
    const isSortDirDesc = ref(true)

    return {
      sortBy,
      isSortDirDesc,
    }
  },
  data() {
    return {
      defaultTableColumns: [
        { key: 'name', sortable: true, label: 'Nazwa szkolenia' },
        { key: 'date', sortable: true, label: 'Data' },
        { key: 'time', label: 'Godzina startu' },
        { key: 'localization', label: 'Lokalizacja' },
      ],
      managerPastTableColumns: [
        { key: 'name', sortable: true, label: 'Nazwa szkolenia' },
        { key: 'departments', label: 'Dział' },
        { key: 'invoices', label: 'Faktura' },
      ],
      managerAvailableTrainingsColumns: [
        { key: 'lastName', sortable: true, label: 'Nazwisko' },
        { key: 'firstName', label: 'Imię' },
        { key: 'departments', label: 'Dział' },
        { key: 'name', label: 'Nazwa szkolenia' },
        { key: 'date', sortable: true, label: 'Data' },
        { key: 'time', label: 'Godzina startu' },
        { key: 'localization', label: 'Lokalizacja' },
        { key: 'priceInGrosze', label: 'Koszt szkolenia (netto)' },
        { key: 'actions', label: 'Akcje' },

      ],
    }
  },
  computed: {
    tableColumns() {
      if (this.managerPastFields) {
        return this.managerPastTableColumns
      }
      if (this.managerAvailableTrainingsFields) {
        return this.managerAvailableTrainingsColumns
      }
      return this.defaultTableColumns
    },
  },
  methods: {
    resolveDepartment,
    resolveDepartments,
    trainingCourseIsUpcoming,
    trainingCourseDetailsRedirect(trainingCourse) {
      this.$router.push(`/training-courses/details/${trainingCourse.id}`)
    },
    downloadInvoice(invoice) {
      window.open(invoice.downloadUrl)
    },
  },
}
</script>

<style>

</style>
