var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{attrs:{"responsive":"","fields":_vm.tableColumns,"items":_vm.items,"empty-text":"Nie znaleziono szkoleń.","tbody-tr-class":"cursor-pointer","hover":"","show-empty":"","sort-by":_vm.sortBy,"sort-desc":_vm.isSortDirDesc,"busy":_vm.busy},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event},"row-clicked":_vm.trainingCourseDetailsRedirect},scopedSlots:_vm._u([(!_vm.items.length)?{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_vm._v(" Pobieranie listy szkoleń ")],1)]},proxy:true}:null,{key:"cell(date)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm._f("date")(item.date))),_c('br'),_vm._v(_vm._s(_vm._f("date")(item.endDate))+" ")])]}},{key:"cell(time)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm._f("time")(item.date))+" ")])]}},{key:"cell(department)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.resolveDepartment(item.department))+" ")]}},{key:"cell(departments)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.resolveDepartments(item.departments))+" ")]}},{key:"cell(invoices)",fn:function(ref){
var item = ref.item;
return _vm._l((item.invoices),function(invoice){return _c('b-button-group',{key:invoice.id,staticClass:"w-100 mb-50",attrs:{"size":"sm"}},[_c('b-button',{staticClass:"btn-icon",attrs:{"variant":invoice.isPaid ? 'success' : 'danger',"title":invoice.isPaid ? 'Opłacona' : 'Nieopłacona'}},[_c('feather-icon',{attrs:{"icon":"FileIcon"}})],1),_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"light","title":"Pobierz"},on:{"click":function($event){return _vm.downloadInvoice(invoice)}}},[_c('feather-icon',{attrs:{"icon":"DownloadIcon"}})],1)],1)})}},{key:"cell(priceInGrosze)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("priceInGrosze")(item.priceInGrosze))+" ")]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticStyle:{"white-space":"nowrap"}},[(_vm.isManager && _vm.trainingCourseIsUpcoming(data.item))?_c('b-button',{attrs:{"size":"sm","variant":"primary","disabled":_vm.busy},on:{"click":function($event){return _vm.$emit('quickEnrolment', data.item)}}},[_c('feather-icon',{attrs:{"icon":"UserPlusIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Zapisz na szkolenie")])],1):_vm._e()],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }