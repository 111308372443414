<template>
  <div>
    <template v-if="isEmployee || isTrainer || isManager">
      <b-row>
        <b-col
          cols="12"
          :md="isTrainer ? null : 4"
        >
          <b-card
            class="stretch-card"
            bg-variant="gradient-primary"
          >
            Witaj,
            <h3 style="color: #fff">
              {{ currentUser.firstName }} {{ currentUser.lastName }}
            </h3></b-card>
        </b-col>
        <b-col
          cols="12"
          md="8"
        >
          <b-card
            v-if="!isTrainer"
            class="stretch-card"
            bg-variant="gradient-primary"
            style="opacity: 0.8"
          >
            Dealer
            <h3 style="color: #fff">
              <strong>{{ currentUser.dealer.dealerNo }}</strong>
              {{ currentUser.dealer.name }}:
            </h3>
            <ul>
              <li
                v-for="department in currentUser.departments"
                :key="department"
              >
                {{ resolveDepartment(department) }}
              </li>
            </ul></b-card>
        </b-col>
      </b-row>
      <hr style="margin-top: -14px">
      <b-row v-if="isManager">
        <b-col cols="12">
          <b-card
            no-body
            class="stretch-card"
          >
            <h4 class="p-2 mb-0">
              Dostępne szkolenia
            </h4>
            <!-- <dashboard-courses-table
              :busy="loading"
              :items="managerAvailableTrainingsPerUserList"
              manager-available-trainings-fields
              @quickEnrolment="quickEnrolment"
            /> -->
            <b-button
              to="/training-courses/"
              variant="gradient-primary"
              class="mx-2 mb-2"
            >
              Zobacz dostępne szkolenia
            </b-button>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <b-card
            class="stretch-card"
            no-body
          >
            <h4 class="p-2 mb-0">
              Nadchodzące szkolenia Pracowników
            </h4>
            <dashboard-courses-table
              :busy="loading"
              :items="managerEmployeesUpcomingTrainingCourses"
              empty-text="Brak nadchodzących szkoleń"
            />
          </b-card>
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <b-card
            no-body
            class="stretch-card"
          >
            <h4 class="p-2 mb-0">
              Ukończone szkolenia Pracowników
            </h4>
            <dashboard-courses-table
              :busy="loading"
              :items="managerEmployeesPastTrainingCourses"
              :manager-past-fields="isManager"
            />
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <b-card
            class="stretch-card"
            no-body
          >
            <h4 class="p-2 mb-0">
              Twoje nadchodzące szkolenia
            </h4>
            <dashboard-courses-table
              :busy="loading"
              :items="upcomingTrainingCourses"
              empty-text="Brak nadchodzących szkoleń"
            />
          </b-card>
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <b-card
            no-body
            class="stretch-card"
          >
            <h4 class="p-2 mb-0">
              {{ isTrainer ? 'Twoje przeprowadzone szkolenia' : 'Twoje ukończone szkolenia' }}
            </h4>
            <dashboard-courses-table
              :busy="loading"
              :items="pastTrainingCourses"
              :manager-past-fields="isManager"
            />
          </b-card>
        </b-col>
      </b-row>
    </template>
    <template v-else>
      Przekierowuję...
    </template>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BButton,
} from 'bootstrap-vue'
import resolveDepartment from '@/helpers/resolveDepartment'
import dayjs from 'dayjs'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DashboardCoursesTable from './DashboardCoursesTable.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    DashboardCoursesTable,
  },
  data() {
    return {
      loading: true,
      managerEnrolledList: null,
      managerEnrolledEmployeesList: null,
      managerAvailableTrainingsList: [],
    }
  },
  computed: {
    trainingCoursesList() {
      let list
      if (this.isManager) {
        list = this.managerEnrolledList ? this.managerEnrolledList['hydra:member'] : []
      } else {
        list = this.$store.state.trainingCourse.list
          ? this.$store.state.trainingCourse.list['hydra:member']
          : []
      }
      return list
    },

    upcomingTrainingCourses() {
      return this.trainingCoursesList.filter(trainingCourse => dayjs(trainingCourse.date).isAfter(dayjs()))
    },
    pastTrainingCourses() {
      return this.trainingCoursesList.filter(trainingCourse => dayjs(trainingCourse.date).isBefore(dayjs()))
    },

    managerEmployeesUpcomingTrainingCourses() {
      const list = this.managerEnrolledEmployeesList ? this.managerEnrolledEmployeesList['hydra:member'] : []
      return list.filter(trainingCourse => dayjs(trainingCourse.date).isAfter(dayjs()))
    },
    managerEmployeesPastTrainingCourses() {
      const list = this.managerEnrolledEmployeesList ? this.managerEnrolledEmployeesList['hydra:member'] : []
      return list.filter(trainingCourse => dayjs(trainingCourse.date).isBefore(dayjs()))
    },

    managerAvailableTrainingsPerUserList() {
      const list = []
      this.managerAvailableTrainingsList.forEach(user => {
        user.availableTrainings.forEach(trainingCourse => {
          list.push({
            lastName: user.lastName,
            firstName: user.firstName,
            department: trainingCourse.department,
            name: trainingCourse.name,
            date: trainingCourse.date,
            localization: trainingCourse.localization,
            priceInGrosze: trainingCourse.priceInGrosze,
            userId: user['@id'],
            trainingCourseId: trainingCourse['@id'],
            id: trainingCourse.id,
          })
        })
      })

      return list
    },
  },
  mounted() {
    if (this.isManager) {
      this.fetchManagerData()
    } else {
      this.fetchData()
    }
  },
  beforeMount() {
    if (this.isAdmin || this.isFordPoland) {
      this.$router.replace('/users/list')
    }
  },
  methods: {
    resolveDepartment,
    fetchData() {
      this.loading = true
      this.$store.dispatch('trainingCourse/fetchList', this.isEmployee ? { params: { enrolled: true } } : null).finally(() => {
        this.loading = false
      })
    },
    fetchManagerData() {
      this.loading = true
      Promise.all([
        this.$store.dispatch('trainingCourse/fetchList', { params: { enrolled: true } }).then(response => {
          this.managerEnrolledList = response.data
        }),
        this.$store.dispatch('trainingCourse/fetchList', { params: { hasEnrolledEmployees: true } }).then(response => {
          this.managerEnrolledEmployeesList = response.data
        }),
        this.$http.get('users/available_trainings').then(response => {
          this.managerAvailableTrainingsList = response && response.data ? response.data['hydra:member'] : []
        }),
      ]).finally(() => { this.loading = false })
    },

    quickEnrolment(availableTraining) {
      function getError(error) {
        switch (error) {
          case 'trainingCourse: There are no free places in this training course.': return 'Nie ma dostępnych miejsc na to szkolenie'
          default: return error
        }
      }

      this.loading = true

      this.$store.dispatch('trainingCourse/addEnrolment', {
        trainingCourse: availableTraining.trainingCourseId,
        user: availableTraining.userId,
      }).then(response => {
        if (response && response.status === 201) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                'Zapisano pracownika na szkolenie',
              icon: 'UserPlusIcon',
              variant: 'success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Nie udało się wysłać zaproszenia.',
              text: response ? getError(response.data['hydra:description']) : null,
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }
      }).finally(() => {
        this.fetchManagerData()
      })
    },
  },
}
</script>

<style lang="scss">
.stretch-card {
  height: calc(100% - 28px);
  overflow: hidden;

  .table-responsive {
    margin-bottom: 0;
  }
}
</style>
